<template>
<div class="flex items-center space-x-1 mt-1">
    <ArrowCircleRightIcon class="flex-shrink-0 w-4 h-4 text-red-600" aria-hidden="true"/>

    <div class="text-xs text-red-600">
        <div v-if="state.type == 'email'">
            <span v-if="state.v.$errors[0].$validator == 'required'">El correo es requerido.</span>
            <span v-if="state.v.$errors[0].$validator == 'email'">El correo no es válido.</span>
        </div>

        <div v-if="state.type == 'pwd'">
            <span v-if="state.v.$errors[0].$validator == 'required'">La contraseña es requerida.</span>
            <span v-if="state.v.$errors[0].$validator == 'minLength'">Se requiere mínimo 6 caracteres.</span>
        </div>

        <div v-if="state.type == 'pwdConfirm'">
            <span v-if="state.v.$errors[0].$validator == 'required'">Confirme la contraseña.</span>
            <span v-if="state.v.$errors[0].$validator == 'sameAs'">Las contraseñas no coinciden.</span>
        </div>

        <div v-if="state.type == 'acceptConditions'">
            <span v-if="state.v.$errors[0].$validator == 'required'">Debe aceptar los términos y condiciones.</span>
            <span v-if="state.v.$errors[0].$validator == 'sameAs'">Debe aceptar los términos y condiciones.</span>
        </div>
    </div>
</div>
</template>

<script>
import { ArrowCircleRightIcon } from '@heroicons/vue/solid'
import { reactive } from 'vue'

export default {
    props: ['v', 'type'],
    components: {
        ArrowCircleRightIcon
    },
    setup(props) {
        const state = reactive({
            v: props.v,
            type: props.type
        });

        return {
            state
        }
    }
}
</script>