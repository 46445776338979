<template>
	<div class="fixed">
		<img :src="require('@/assets/core/logo.png')" alt="Chiesi México" class="w-48 sm:w-56">
	</div>

	<div class="min-h-screen bg-white flex">
		<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
			<div class="mx-auto w-full max-w-sm lg:w-96">
				<div>
					<!-- <img class="w-24" :src="require('@/assets/core/logo-sayls.png')" alt="Chiesi Sayl´s" /> -->
					<h2 class="mt-16 sm:mt-6 text-3xl font-extrabold text-accent">
						Bienvenido(a)
					</h2>
					<p class="mt-2 text-sm text-gray-600">
						Entra y participa en nuestra dinámica.
					</p>
				</div>

				<div class="mt-8">
					<div class="mt-6">
						<form @submit.prevent="submitForm" class="space-y-6">
							<div>
								<label for="email" class="block text-sm font-medium text-gray-700">
									Correo electrónico
								</label>
								<div class="mt-1">
									<input v-model="state.model.email" type="email" :disabled="state.sending" autocomplete="email" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-accent focus:border-accent sm:text-sm" />
									<ErrorMessages 
										:v="v$.model.email" 
										type="email"
										v-if="v$.model.email.$error"/>
								</div>
							</div>

							<div class="space-y-1">
								<label for="password" class="block text-sm font-medium text-gray-700">
									Contraseña
								</label>
								<div class="mt-1">
									<input v-model="state.model.pwd" type="password" :disabled="state.sending" autocomplete="current-password" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-accent focus:border-accent sm:text-sm" />
									<ErrorMessages 
										:v="v$.model.pwd" 
										type="pwd"
										v-if="v$.model.pwd.$error"/>
								</div>
							</div>

							<div class="flex items-center justify-end">
								<div class="text-xs">
									<router-link to="recover-password" class="text-primary hover:text-accent" v-if="!state.sending">
										¿Olvidaste tu contraseña?
									</router-link>
									<span class="text-gray-400" v-else>
										¿Olvidaste tu contraseña?
									</span>
								</div>
							</div>

							<div>
								<button type="submit" v-if="!state.sending"  class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent">
									Entrar
								</button>

								<div v-if="state.sending" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-400 bg-gray-200">
									Espera un momento...
								</div>
							</div>
						</form>
					</div>

					<div class="mt-10 relative">
						<div class="absolute inset-0 flex items-center" aria-hidden="true">
							<div class="w-full border-t border-gray-300" />
						</div>
						<div class="relative flex justify-center text-sm">
							<span class="px-2 bg-white text-gray-500">
								¿Aún no te has registrado?
							</span>
						</div>
					</div>

					<div class="mt-5">
						<router-link to="/signup"  v-if="!state.sending" class="w-full flex justify-center py-2 px-4 border border-primary rounded-md shadow-sm text-sm font-medium hover:text-white text-primary hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent">
							Registrarme
						</router-link>
						<span v-else class="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium  text-gray-400">
							Registrarme
						</span>
					</div>

					<FooterPublic class="mt-12" />
				</div>
			</div>
		</div>
		<div class="hidden lg:block relative w-0 flex-1">
			<img class="absolute inset-0 h-full w-full object-cover" :src="require('@/assets/core/general.png')" alt="" />
		</div>
	</div>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, email, minLength, sameAs, helpers } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
import { useStore } from 'vuex'
import ErrorMessages from '@/components/core/ErrorMessages'
import Sign from '@/firebase/sign'
import FooterPublic from '@/components/core/FooterPublic'
import FireErrors from '@/services/fireErrors'

export default {
	components: {
		ErrorMessages,
		FooterPublic
	},
	setup() {
		const store = useStore();

		const state = reactive({
			model: {
				email: '',
				pwd: ''
			},
			sending: false
		});

		const rules = computed(() => {
			return {
				model: {
					email: { 
						required
					},
					pwd: { 
						required
					},
				}
			}
		});

		const v$ = useValidate(rules, state);

		function submitForm() {
			v$.value.$validate()

			if (!v$.value.$error) {
				state.sending = true;

				Sign.signin(state.model)
					.then(response => {
						if (response != true) {
							state.sending = false;
                     		window.scrollTo(0, 0);
							store.dispatch('setNotification', {type: false, content: FireErrors.get(response)});
						}
					})
			}
		}

		return {
			state,
			v$,
			submitForm
		}
	}
}
</script>


